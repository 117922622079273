<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Заявка на карту Газпромбанк (ID: {{ $route.params.id }})</h1>
    </div>
    <form method="post" @submit.prevent="checkForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label>Мероприятие <sup>*</sup></label>
            <v-select
              v-if="eventsOptions.length"
              v-model="form.event"
              :options="eventsOptions"
              :reduce="event => event.id"
              :filterable="false"
              :clearable="true"
              :searchable="false"
              label="id"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.event.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #no-options>Ничего не найдено</template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <div v-else class="form-control">
              <input
                type="text"
                class="form-control__input"
                :value="singleEventName"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.event.$dirty && !$v.form.event.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="lastname">Фамилия <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.lastname.$error }">
              <input
                id="lastname"
                type="text"
                class="form-control__input"
                name="lastname"
                placeholder="Введите вашу фамилию"
                v-model.trim="form.lastname"
                @change="$v.form.lastname.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.lastname.$dirty && !$v.form.lastname.ruAlpha">Принимаются только русские буквы алфавита</div>
          </div>
          <div class="form-group">
            <label for="firstname">Имя <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.firstname.$error }">
              <input
                id="firstname"
                type="text"
                class="form-control__input"
                name="firstname"
                placeholder="Введите ваше имя"
                v-model.trim="form.firstname"
                @change="$v.form.firstname.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.firstname.$dirty && !$v.form.firstname.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
          </div>
          <div class="form-group">
            <label for="middlename">Отчество</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.patronymic.$error }">
              <input
                id="middlename"
                type="text"
                class="form-control__input"
                name="middlename"
                placeholder="Введите ваше отчество"
                v-model.trim="form.patronymic"
                @change="$v.form.patronymic.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.patronymic.$dirty && !$v.form.patronymic.ruAlpha">
              Принимаются только русские буквы алфавита
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.gender.$error }">
            <label>Пол <sup>*</sup></label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Мужской</div>
                <input type="radio" name="gender" v-model="form.gender" value="1"/>
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Женский</div>
                <input type="radio" name="gender" v-model="form.gender" value="2" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.gender.$dirty && !$v.form.gender.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.dob.$error || $v.age.$invalid }">
            <label>Дата рождения <sup>*</sup></label>
            <date-picker
              @close="onCheckBirth"
              v-model="form.dob"
              name="dob"
              format="DD.MM.YYYY"
              value-type="DD.MM.YYYY"
              placeholder="дд.мм.гггг"
              :editable="true"
              ref="datepicker"
              :class="{ 'mx-datepicker--error': $v.form.dob.$error || $v.age.$invalid }"
            >
              <template #input="{ props, event }">
                <masked-input
                  mask="11.11.1111"
                  :value="props.value"
                  v-bind="props"
                  v-on="event"
                  v-model="form.dob"
                />
              </template>
            </date-picker>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.form.dob.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.age.minValue">Минимальный возраст оформления карты - 18 лет</div>
            <div class="form-group__error" v-if="$v.form.dob.$dirty && !$v.age.maxValue">Максимальный возраст оформления карты - 25 лет</div>
          </div>
          <div class="form-group">
            <label>Телефон <sup>*</sup></label>
            <vue-tel-input
              :value="phoneValue"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': $v.form.phone.$dirty && !isPhoneValid }"
              @validate="validedPhone"
              @blur="$v.form.phone.$touch()"
              disabledFetchingCountry
              validCharactersOnly
              dynamicPlaceholder
              autocomplete="phone_autocomplete_off"
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z" fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.phone.$dirty && !isPhoneValid">Недопустимое значение для номера телефона</div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="email">Электронная почта <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите ваш email"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
          <div class="form-group">
            <label>Адрес проживания <sup>*</sup></label>
            <v-select
              :options="addressesOptions"
              label="address"
              v-model="form.residence_address"
              :filterable="false"
              :clearable="false"
              @search="onAddressSearch"
              class="select"
              :class="{ 'select--error': $v.form.residence_address.$error }"
              placeholder="Выберите адрес проживания"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">введите для поиска свой адрес..</template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.address }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.residence_address.$dirty && !$v.form.residence_address.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="passport_series">Серия паспорта <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.passport_series.$error }">
              <input
                id="passport_series"
                type="text"
                class="form-control__input"
                name="passport_series"
                v-model.trim="form.passport_series"
                @change="$v.form.passport_series.$touch()"
                placeholder="Введите серию паспорта"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.passport_series.$dirty && !$v.form.passport_series.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="passport_number">Номер паспорта <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.passport_number.$error }">
              <input
                id="passport_number"
                type="text"
                class="form-control__input"
                name="passport_number"
                v-model.trim="form.passport_number"
                @change="$v.form.passport_number.$touch()"
                placeholder="Введите номер паспорта"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.passport_number.$dirty && !$v.form.passport_number.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="passport_fms_name">Кем выдан паспорт <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.passport_fms_name.$error }">
              <input
                id="passport_fms_name"
                type="text"
                class="form-control__input"
                name="passport_fms_name"
                v-model.trim="form.passport_fms_name"
                @change="$v.form.passport_fms_name.$touch()"
                placeholder="Введите кем выдан паспорт"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.passport_fms_name.$dirty && !$v.form.passport_fms_name.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="passport_date">Дата выдачи паспорта</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.passport_date.$error }">
              <masked-input
                v-model="form.passport_date"
                name="passport_date"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="passport_date"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.passport_date.$dirty && !$v.form.passport_date.required">Обязательное поле</div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button type="submit" class="button" ref="submit" :disabled="$v.form.$error || $v.age.$invalid">
          <span>Сохранить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email, helpers, minValue, maxValue } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import { debounce } from 'lodash';
import MaskedInput from 'vue-masked-input';
import { VueTelInput } from 'vue-tel-input';

const ruAlpha = helpers.regex('ruAlpha', /[а-яёА-ЯЁ]/i);

export default {
  name: 'RequestGPB',
  mixins: [ validationMixin ],
  components: {
    DatePicker,
    MaskedInput,
    VueTelInput
  },
  validations: {
    form: {
      event: { required },
      lastname: { required, ruAlpha },
      firstname: { required, ruAlpha },
      patronymic: { ruAlpha },
      gender: { required },
      dob: { required },
      phone: { required },
      email: { required, email },
      residence_address: { required },
      passport_series: { required },
      passport_number: { required },
      passport_fms_name: { required },
      passport_date: { required },
    },
    age: { minValue: minValue(18), maxValue: maxValue(25) },
  },
  data() {
    return {
      form: {},
      singleEventName: '',
      eventsOptions: [],
      addressesOptions: [],
      isPhoneValid: null,
      phoneValue: '',
      age: null,
    };
  },
  mounted() {
    this.$store.dispatch('overall/GET_EVENTS')
      .then(response => {
        if (response.data.length > 1) {
          this.eventsOptions = response.data;
        } else {
          this.singleEventName = response.data[0].name;
          this.form.event = response.data[0].id;
        }
      })
      .catch(() => {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка получения данных о мероприятиях'
        });
      });
    this.$store.dispatch('admin/cardsGpb/GET_REQUEST', this.$route.params.id)
      .then(response => {
        this.form = response.data;
        this.phoneValue = response.data.phone;
      })
      .catch(() => {
        this.$notify({
          type: 'error',
          title: 'Внимание!',
          text: 'Ошибка получения данных о заявки'
        });
      });
  },
  methods: {
    checkForm () {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm();
      }
    },
    sendForm () {
      this.$refs.submit.classList.add('preload');
      this.form.residence_address_text = this.form.residence_address.address;
      this.$store.dispatch('admin/cardsGpb/PATCH_REQUEST', { id: this.$route.params.id, form: this.form })
        .then(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные заявки успешно сохранены.'
          });
          this.$router.push({ name: 'requests-gpb' });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: 'Ошибка отправки данных о заявки'
          });
        });
    },
    onAddressSearch (search, loading) {
      loading(true);
      this.searchAddress(loading, search, this);
    },
    searchAddress: debounce((loading, search, vm) => {
      vm.$store.dispatch('overall/GET_ADDRESS', {search}).then(response => {
        vm.addressesOptions = response.data;
        loading(false);
      });
    }, 350),
    validedPhone (number) {
      this.isPhoneValid = number.valid;
      if (number.valid) {
        this.form.phone = number.number.e164;
      } else {
        this.form.phone = null;
      }
    },
    onCheckBirth() {
      this.$v.form.dob.$touch();
      if (this.form.dob) {
        const today = new Date();
        const formatBirth = this.form.dob.slice(3, 6) + this.form.dob.slice(0, 3) + this.form.dob.slice(6);
        const birthDate = new Date(formatBirth);
        this.age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) this.age--;
      } else {
        this.age = 0;
      }
    },
  }
};
</script>
